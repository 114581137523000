<template>
  <div v-if="loaded">
    <v-text-field :rules="[value => !!value || 'Full Name required.']"
      :label="$t('full_name')"
      :hint="$t('full_name_bank_account_hint')"
      persistent-hint
      v-model="bankDetails.name"
      :readonly="readonly ? readonly : false"
    />

    <v-text-field 
      :rules="generateCountryIdRules()"
      :label="getCountryIdText()"
      v-model="bankDetails.idNumber"
      persistent-hint
      :readonly="readonly ? readonly : false"
    />
    
    <v-text-field
      :rules="[value => !!value || `${$t('country')} ${$t('required')}`]"
      :label="$t('country')"
      v-model="bankDetails.country"
      readonly
    />

    <v-select v-if="readonly != true && showBankList == true"
      :rules="[value => !!value || `${$t('bank_name')} ${$t('required')}`]"
      :items="banks"
      :label="$t('bank_name')"
      item-text="name"
      v-model="bankDetails.bankName"
      @input="updateBankRules"
      return-object
    />
    <v-text-field v-else
      :label="$t('bank_name')"
      v-model="bankDetails.bankName"
      :readonly="readonly ? readonly : false"
    />

    <v-text-field :rules="accountNumberRules"
      :label="$t('account_number')"
      v-model="bankDetails.accountNumber"
      :readonly="readonly ? readonly : false"
    />

  </div>
</template>

<script>
import { DataService } from "../../services/DataService";
const dataService = new DataService();
export default {
  props: {
    readonly: Boolean,
    bankDetails: Object,
  },
  data() {
    return {
      loaded: false,
      accountNumberRules: [value => !!value || `${this.$t('account_number')} ${this.$t('required')}` ],
      banks: [],
      countryIdMapping: {},
      showBankList: true
    }
  },
  created() {
    dataService.getConfig(`countryIdNumberRules`).then(data => {
      this.countryIdMapping = data
    })
  },
  mounted() {
    if (this.bankDetails.country != undefined) {
      this.getBankList()
    }
  },
  methods: {
    getBankList() {
      this.$store.state.loader = true

      dataService.getConfig(`bank${this.bankDetails.country}`).then(data => {
        this.$store.state.loader = false
        this.banks = data
        if (data.length == 0) {
          this.showBankList = false
        }
        let found = this.banks.filter(bank => bank.name == this.bankDetails.bankName)
        
        if (found.length == 1) {
          this.updateBankRules(found[0])
        }

        this.loaded = true
      })
    },
    generateCountryIdRules() {
      let rules = []
      let countryIdRulesMap = this.countryIdMapping[this.bankDetails.country] ? this.countryIdMapping[this.bankDetails.country] : {}

      let countryIdLabel = countryIdRulesMap.label ? countryIdRulesMap.label : "ID Number"
      this.bankDetails.idLabel = countryIdLabel

      rules.push(value => !!value || `${countryIdLabel} ${this.$t('required')}`)
      rules.push(value => (countryIdRulesMap.countryIdRules ? new RegExp(countryIdRulesMap.countryIdRules).test(value) : true) || (countryIdRulesMap.regexText ? countryIdRulesMap.regexText : "Please verify your ID number format"))
      rules.push(value => (value || '').length >= (countryIdRulesMap.min || 1) || this.$t('min_characters', { 1: countryIdRulesMap.min || 1 }))
      rules.push(value => (value || '').length <= (countryIdRulesMap.max || 20) || this.$t('max_characters', { 1: countryIdRulesMap.max || 20 }))
      
      return (countryIdRulesMap == undefined || countryIdRulesMap == null) ? [] : rules
    },
    getCountryIdText() {
      if (this.countryIdMapping[this.bankDetails.country] && this.countryIdMapping[this.bankDetails.country].label) {
        return this.countryIdMapping[this.bankDetails.country].label
      } else {
        return "ID Number"
      }
    },
    updateBankRules(data) {
      this.bankDetails.bankName = data.name
      this.accountNumberRules = [
        value => !!value || `${this.$t('account_number')} ${this.$t('required')}`,
        value => (value || '').length >= data.min || this.$t('min_characters', { 1: data.min }),
        value => (value || '').length <= data.max || this.$t('max_characters', { 1: data.max }),
      ]
    }
  }
}
</script>