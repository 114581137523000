<template>
  <v-container fluid>
    <v-form ref="form">
      <BankDetailsForm v-if="loaded"
        :bankDetails="bankDetails"
      />

      <!-- <v-btn color="primary" v-on:click="$router.go(-1)" class="mr-2">Back</v-btn> -->
      <v-btn color="primary" v-on:click="updateDetails">{{$t('update')}}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { DataService } from "../../services/DataService";
import BankDetailsForm from "./form.vue"

const dataService = new DataService();
export default {
  components: {
    BankDetailsForm
  },
  data() {
    return {
      loaded: false,
      bankDetails: {}
    }
  },
  mounted() {
    let country = this.$store.state.userProfile.demographics.find(demographic => demographic.title == "Country")
    this.bankDetails.country = country.answer
    
    if (this.$store.state.userProfile.bankDetails && Object.keys(this.$store.state.userProfile.bankDetails).length > 0 && this.$store.state.userProfile.bankDetails.country == this.bankDetails.country) {
      this.bankDetails = this.$store.state.userProfile.bankDetails
    } else { 
      this.bankDetails.name = this.$store.state.userProfile.name
    }
    this.loaded = true
  },
  methods: {
    updateDetails() {
      if (this.$refs.form.validate()) {
        let data = {
          userId: this.$store.state.userProfile._id,
          bankDetails: this.bankDetails,
        }
        this.$store.state.loader = true
        dataService.updateMobileUser(this.$store.state.token, data).then(() => {
          this.$store.state.loader = false
          this.$store.state.snackBarText = "Successfully updated Bank Account"
          this.$store.state.showSnackBar = true
          // this.$router.go(-1)
        }).catch(() => {
          this.$store.state.loader = false
          this.$store.state.snackBarText = "Error updating Bank Account details"
          this.$store.state.showSnackBar = true
        })
      }
    }
  }
}
</script>